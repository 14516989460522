// src/data.js

import * as Icons from "./Components/Icons";

export const projects = [
	{
		title: "MedCodeAI",
		subtitle: "React Hooks, Typescript, API calls, and AI",
		description:
			"Medical Billing AI app that tells you CPT and ICD10 codes and Reinbursement Rates",
		image: "https://i.ibb.co/yddtPpN/Screenshot-2024-06-27-144918.png",
		link: "https://medcoderai.netlify.app/",
	},
	{
		title: "React Multistep Form",
		subtitle: "React and Bootstrap",
		description: "Simple Form using States and Hooks ",
		image: "https://i.ibb.co/2nMdhq8/Screenshot-2024-05-23-174414.png",
		link: "https://i.ibb.co/2nMdhq8/Screenshot-2024-05-23-174414.png",
	},
	{
		title: "Pomodoro Timer",
		subtitle: "React and CSS",
		description: "This Project displays  State Hook usage ",
		image: "https://i.ibb.co/jVHFv7c/Screenshot-2024-05-23-174701.png",
		link: "https://pomodorotimer224.netlify.app",
	},
	{
		title: "Weather App",
		subtitle: "React, Bootstrap, and CSS",
		description:
			"Will display the weather of any city in the United States currently, this displays API knowledge",
		image: "https://i.ibb.co/2MntG5B/Screenshot-2024-05-23-174752.png",
		link: "https://google.com",
	},
	{
		title: "Simple To Do App",
		subtitle: "React Hooks",
		description:
			"This is a really simple App that shows simplicity and functionality",
		image: "https://i.ibb.co/GPdW14v/Screenshot-2024-05-23-180134.png",
		link: "https://pythonbootcamp.com",
	},
	{
		title: "Pokémon list",
		subtitle: "React Hooks, useState, API",
		description:
			"Used React hooks to load an API using useffect and search is queried using UseState",
		image: "/assets/images/pokemon.png",
		link: "https://maaz224.github.io/POKEMON/",
	},
];
// src/data.js
export const skills_old = [
	"HTML",
	"CSS",
	"JavaScript",
	"TypeScript",
	"React",
	"Bootstrap",
	"Node.js",
	"Material UI",
];

export const skills = [
	{
		title: "HTML",
		icon: (props) => <Icons.HTMLIcon width={24} heigth={24} {...props} />,
	},
	{
		title: "CSS",
		icon: (props) => <Icons.CSSIcon width={24} heigth={24} {...props} />,
	},
	{
		title: "JavaScript",
		icon: (props) => (
			<Icons.JavaScriptIcon width={24} heigth={24} {...props} />
		),
	},
	{
		title: "TypeScript",
		icon: (props) => (
			<Icons.TypeScriptIcon width={24} heigth={24} {...props} />
		),
	},
	{
		title: "React",
		icon: (props) => <Icons.ReactIcon width={24} heigth={24} {...props} />,
	},
	{
		title: "Bootstrap",
		icon: (props) => (
			<Icons.BootstrapIcon width={24} heigth={24} {...props} />
		),
	},
	{
		title: "Node.js",
		icon: (props) => <Icons.NodeIcon width={24} heigth={24} {...props} />,
	},
	{
		title: "Material UI",
		icon: (props) => (
			<Icons.MaterialIcon width={24} heigth={24} {...props} />
		),
	},
	{
		title: "Tailwind CSS",
		icon: (props) => (
			<Icons.TailwindIcon width={24} heigth={24} {...props} />
		),
	},
	{
		title: "Express.js",
		icon: (props) => (
			<Icons.ExpressIcon width={24} heigth={24} {...props} />
		),
	},
	{
		title: "MongoDB",
		icon: (props) => (
			<Icons.MongoDBIcon width={24} heigth={24} {...props} />
		),
	},
	{
		title: "Prisma",
		icon: (props) => <Icons.PrismaIcon width={24} heigth={24} {...props} />,
	},
	{
		title: "Github",
		icon: (props) => <Icons.GithubIcon width={24} heigth={24} {...props} />,
	},
	{
		title: "JQuery",
		icon: (props) => <Icons.JQueryIcon width={24} heigth={24} {...props} />,
	},
	{
		title: "Frontend & Backend Tools",
		icon: (props) => (
			<Icons.WeblateIcon width={24} heigth={24} {...props} />
		),
	},
	{
		title: "End to End Development",
		icon: (props) => <Icons.DevIcon width={24} heigth={24} {...props} />,
	},
];
